import { useUserIdForCarrierQuery } from '../../generatedX/graphql';

const useUserIdForCarrier = () => {
  const { data, loading } = useUserIdForCarrierQuery();

  const userIdForCarrier = (() => {
    if (!data) return undefined;
    if (!data.meAuth0) return undefined;

    return data.meAuth0.repCode ?? undefined;
  })();

  return {
    userIdForCarrier,
    loading,
  };
};

export default useUserIdForCarrier;
